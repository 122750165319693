<script setup>

import { computed, ref } from 'vue';

const columns= ref([[], []]);
const result = ref(0);
const sumA = ref(0);
const sumB = ref(0);
const showText = computed(() => {
  if (result.value >= 0)
  return `琴总：${sumB.value}， 卷总：${sumA.value}，琴给卷${Math.abs(result.value)}`;
  return `琴总：${sumB.value}， 卷总：${sumA.value}，卷给琴${Math.abs(result.value)}`; 
});

function addColumnInput(columnIndex) {
  columns.value[columnIndex].push({});
}

function calculateResult() {
  sumA.value = columns.value[0].reduce((sum, input) => sum + Number(input.value), 0);
  sumB.value = columns.value[1].reduce((sum, input) => sum + Number(input.value), 0);
  result.value = (sumA.value - (sumA.value + sumB.value) / 2).toFixed(2);
}
</script>

<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div id="app">
    <div class="container">
      <div class="column">
        <button @click="addColumnInput(0)">卷</button>
        <div v-for="(input, index) in columns[0]" :key="index">
          <input type="text" :placeholder="'Input ' + (index + 1)" v-model="input.value" :oninput="calculateResult" />
        </div>
      </div>
      <div class="column">
        <button @click="addColumnInput(1)">琴</button>
        <div v-for="(input, index) in columns[1]" :key="index">
          <input type="text" :placeholder="'Input ' + (index + 1)" v-model="input.value" :oninput="calculateResult" />
        </div>
      </div>
    </div>
  </div>
  <div class="result-container">
    <button @click="calculateResult">Calculate</button>
    <div class="result">{{ showText }}</div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

.container {
  display: flex;
  justify-content: space-around;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  display: block;
  margin: 5px 0;
}
/* Add this code at the end of the <style> block */
.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.result {
  margin-top: 10px;
}
</style>
